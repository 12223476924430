/* eslint-disable no-console */
export class CCLogger {
  static actionCable(...messages) {
    console.log(`%c[Action Cable]: `, 'color: #039dad;', ...messages);
  }

  static development(...messages) {
    console.log(`%c[TEST]: `, 'color: #8573e7;', ...messages);
  }

  static analiticsEvents(...messages) {
    console.log(`%c[ANALYTICS EVENTS]: `, 'color: #ffd75b;', ...messages);
  }

  static frontendEvents(...messages) {
    console.log(`%c[FRONTEND EVENTS]: `, 'color: #49b77d;', ...messages);
  }

  static table(message) {
    console.table({
      'Time Stamp': new Date(),
      message,
    });
  }
}
